<template>
  <div class="main-body">
    <div>
      <!-- <div class="overview">
        <ul class="flex-wrap flex-h-center">
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.enterpriseCount }}</span
              ><span class="unit">家</span>
            </div>
            <div class="name">入驻企业</div>
          </li>
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.agencyCount }}</span
              ><span class="unit">家</span>
            </div>
            <div class="name">服务机构</div>
          </li>
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.activityCount }}</span
              ><span class="unit">次</span>
            </div>
            <div class="name">办理活动</div>
          </li>
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.processCount }}</span
              ><span class="unit">次</span>
            </div>
            <div class="name">办理业务</div>
          </li>
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.recruitlCount }}</span
              ><span class="unit">条</span>
            </div>
            <div class="name">招聘条数</div>
          </li>
        </ul>
      </div> -->
      <div class="introduce">
        <div class="title-card">
          <div class="cn-name">平台介绍</div>
          <div class="en-name">Introduction to the park</div>
          <div class="h-line"></div>
        </div>
        <div class="introduce-content flex-wrap">
          <div class="content-left flex-wrap flex-between">
            <div class="title">平台介绍</div>
            <div class="content">
              {{ parkInfo.introduceText }}
            </div>
          </div>
          <div class="content-right flex-con">
            <div class="introduce-video">
              <video
                :src="setParkBasicInfo(sysParameterList, 'park_video')"
                :poster="
                  setParkBasicInfo(sysParameterList, 'park_video') +
                  '?x-oss-process=video/snapshot,t_2000,m_fast'
                "
                style="width: 100%; height: 100%"
                id="videoBox"
                ref="videoPlay"
                :controls="controls"
              ></video>
              <div class="video-img" v-show="plays" @click="videoImg">
                <img src="@/assets/image/play.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="honor" id="honor">
      <div>
        <div class="title-card">
          <div class="cn-name">平台荣誉</div>
          <div class="en-name">Park honor</div>
          <div class="h-line"></div>
        </div>
        <div class="honor-list">
          <swiper :options="swiperOption" v-if="honorList.length > 0 && isKeep">
            <swiper-slide v-for="(item, index) in honorList" :key="index">
              <div class="swiper-item flex-wrap flex-vertical flex-h-center">
                <div class="top-decorate"></div>
                <div class="image">
                  <img :src="item.pic" alt="" width="190" height="130" />
                </div>
                <div class="name t-hide">{{ item.title }}</div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div class="swiper-btn button-prev"></div>
      <div class="swiper-btn button-next"></div>
    </div>

    <div class="site" id="site">
      <div>
        <div class="title-card">
          <div class="cn-name">地理位置</div>
          <div class="en-name">Geographical Position</div>
          <div class="h-line"></div>
        </div>
        <div class="content">
          {{ parkInfo.introduceText }}
        </div>
      </div>
      <div class="map">
        <div class="info-box">
          <ul class="flex-wrap flex-vertical flex-center">
            <li></li>
            <li>平台定位</li>
            <li>平台地址：陕西省咸阳市秦都区高科三路中韩产业园</li>
            <li>
              联系电话：{{ setParkBasicInfo(sysParameterList, 'park_phone') }}
            </li>
            <!-- <li>招商电话：029-0000-0000</li> -->
          </ul>
        </div>
        <el-amap vid="amap" :center="center" :zoom="zoom">
          <el-amap-marker
            :icon="markerIcon"
            :offset="[-26, -50]"
            :position="marker.position"
          />
        </el-amap>
      </div>
    </div>

    <div class="message">
      <div>
        <div class="title-card">
          <div class="cn-name">在线留言</div>
          <div class="en-name">Online Message</div>
          <div class="h-line"></div>
        </div>
        <div class="content">
          中韩产业园A区位于陕西省咸阳市秦都区，面积约54.66亩。本数据来自前瞻产业研究院产业平台数据库，前瞻产业研究院20年持续聚焦全国细分产业研究、产业规划、产业平台规划、产业地产规划、特色小镇规划、产业新城规划及产业平台招商引资等，助力地方产业发展，促进产城融合。
        </div>
      </div>
      <div class="message-box">
        <div class="title">在线留言</div>
        <div class="flex-wrap">
          <div class="flex-con"></div>
          <div class="flex-con form-box">
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="姓名" prop="submitter">
                <a-input v-model="form.submitter" />
              </a-form-model-item>
              <a-form-model-item label="联系电话" prop="phone">
                <a-input v-model="form.phone" />
              </a-form-model-item>
              <a-form-model-item label="问题描述" prop="description">
                <a-input
                  v-model="form.description"
                  type="textarea"
                  style="height: 115px"
                />
              </a-form-model-item>
              <a-form-model-item label="附件添加">
                <a-upload
                  :action="uploadAction"
                  :file-list="fileList"
                  @change="handleChange"
                >
                  <a-button class="btn-up-style">
                    <img src="@/assets/image/up.png" alt="" />
                    上传文件
                  </a-button>
                </a-upload>
                <div class="file-type">
                  支持扩展名：rar zip doc docx pdf jpg...
                </div>
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 11, offset: 4 }">
                <a-button
                  :loading="loading"
                  class="s-btn-style"
                  type="primary"
                  @click="onSubmit"
                >
                  提交
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { publicInfoIndex, statisticalPcCount, yqmsMessageAdd } from '@/api/api'
import { setParkBasicInfo } from '@/api/common'
import markerIcon from '@/assets/xi_maker.png'
import reg from '@/api/regular'
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        allowTouchMove: false,
        slidesPerView: 5,
        spaceBetween: 10,
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev',
        },
      },
      isKeep: false,
      plays: true,
      controls: false,
      parkInfo: {
        introduceText: '',
        introduceVideo: '',
      },
      sysParameterList: [],
      markerIcon,
      center: [108.638821, 34.286242],
      zoom: 15,
      marker: {
        position: [108.638821, 34.286242],
      },
      statisticsData: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 11 },
      form: {
        submitter: '',
        phone: '',
        description: '',
        annex: '',
      },
      rules: {
        submitter: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: 'blur',
          },
          {
            required: true,
            pattern: reg.phone,
            message: '请输入正确的联系电话',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: '请输入问题描述',
            trigger: 'blur',
          },
        ],
      },
      loading: false,
      uploadAction: window._CONFIG['domianURL'] + '/oss/file/upload',
      fileList: [],
      honorList: [],
    }
  },
  activated() {
    this.isKeep = true
    this.getInfo()
    this.statisticsInfo()
  },
  deactivated() {
    this.isKeep = false
  },
  created() {
    this.isKeep = true
    this.getInfo()
    this.statisticsInfo()
  },
  mounted() {
    const index = this.$route.params.index
    if (index === 0 || index === 1) {
      this.$nextTick(() => {
        document.querySelector('#site').scrollIntoView(true)
      })
    } else if (index === 2) {
      document.querySelector('#honor').scrollIntoView(true)
    }
  },
  methods: {
    setParkBasicInfo,
    videoImg() {
      this.plays = false
      this.controls = true
      this.$refs['videoPlay'].play()
    },
    async getInfo() {
      try {
        const res = await publicInfoIndex()
        if (res.code == 200) {
          this.sysParameterList = res.result.sysParameterList
          this.honorList = res.result.honorList
          this.setParkInfo()
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    setParkInfo() {
      let parkInfoIntroduce = this.sysParameterList.find((item) => {
        return item.code == 'park_introduce'
      })
      this.parkInfo.introduceText = parkInfoIntroduce.value
    },
    async statisticsInfo() {
      try {
        const res = await statisticalPcCount()
        if (res.code == 200) {
          this.statisticsData = res.result
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = {
            ...this.form,
          }
          console.log(params)
          yqmsMessageAdd(params)
            .then((res) => {
              if (res.success) {
                this.$message.success('留言提交成功')
                this.close()
              } else {
                this.$message.error(res.message)
              }
              this.loading = false
            })
            .catch((err) => {
              this.$message.error(err.message)
              this.loading = false
            })
        }
      })
    },
    close() {
      this.form = {}
      this.fileList = []
    },
    handleChange(info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      this.fileList = fileList
      if (info.file.status === 'done') {
        if (info.file.response.code == 200) {
          this.form.annex = info.file.response.result
        } else {
          this.$message.error('上传失败，请重新上传')
        }
      } else if (info.file.status === 'error') {
        this.$message.error('上传失败，请重新上传')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.main-body {
  background: #f4f4f4;
  > div {
    width: 1200px;
    margin: 0 auto;

    &:nth-child(1) {
      padding-top: 50px;
    }

    .overview {
      background: #ffffff;
      box-shadow: 0px 2px 49px 0px rgba(0, 0, 0, 0.27);
      margin-bottom: 70px;
      ul {
        padding: 27px 0 24px 0;
        margin: 0;
        li {
          color: #1285d6;
          border-right: 2px solid #eee;
          text-align: center;
          padding: 4px 0 24px 0;
          .number {
            font-size: 70px;
            font-weight: bold;
          }
          .unit {
            font-size: 26px;
            font-weight: 400;
          }
          .name {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
          }

          &:last-child {
            border: none;
          }
        }
      }
    }

    .introduce {
      .introduce-content {
        margin: 55px 0 80px 0;
        height: 587px;
        .content-left {
          width: 275px;
          height: 100%;
          background: #2b2b2b;
          padding: 40px 35px;

          .title {
            width: 43px;
            font-size: 30px;
            font-weight: normal;
            color: #fcfcfc;
            line-height: 36px;
            text-align: center;
            margin-top: -6px;
            background-image: url(~@/assets/image/b_icon.png);
            background-repeat: no-repeat;
            background-position: 0 70px;
          }

          .content {
            writing-mode: vertical-rl; /*从左向右 从右向左是 writing-mode: vertical-rl;*/
            writing-mode: tb-lr; /*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
            color: #fcfcfc;
            line-height: 36px;
          }
        }

        .content-right {
          .introduce-video {
            width: 100%;
            height: 100%;
            position: relative;
            #videoBox {
              object-fit: fill;
              display: inline-block;
              vertical-align: baseline;
            }
            .video-img {
              cursor: pointer;
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -57px;
              margin-left: -57px;
            }
            //解决视频有黑色的线框
            video:focus {
              outline: -webkit-focus-ring-color auto 0px;
            }
          }
        }
      }
    }
  }

  .honor {
    background: #fff;
    padding-bottom: 100px;
    width: 100%;
    position: relative;

    > div {
      width: 1200px;
      margin: 0 auto;

      .honor-list {
        margin-top: 100px;
        .swiper-slide {
          background: #ffffff;
          border: 1px solid #d0d0d0;
        }

        .swiper-item {
          padding: 0 20px;
          cursor: pointer;
          .top-decorate {
            width: 30px;
            height: 6px;
            background: #d0d0d0;
          }

          .image {
            margin: 12px 0 15px 0;
          }

          .name {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            padding-bottom: 5px;
            text-align: center;
          }
        }
      }
    }

    .swiper-btn {
      position: absolute;
      top: 300px;
      background: rgba(0, 0, 0, 0.05);
      opacity: 0;
      width: 0;
      height: 58px;
      z-index: 10;
      cursor: pointer;
      transition-property: opacity, width;
      transition-duration: 0.5s;
    }

    .button-prev {
      background-image: url(~@/assets/prev.png);
      left: 0;
    }

    .button-next {
      background-image: url(~@/assets/next.png);
      right: 0;
    }

    &:hover {
      .swiper-btn {
        opacity: 1;
        width: 35px;
      }
    }
  }

  .site {
    width: 100%;
    > div:nth-child(1) {
      width: 1200px;
      margin: 0 auto;

      .content {
        font-weight: 400;
        color: #333333;
        line-height: 31px;
        margin: 55px 0 33px;
      }
    }

    .map {
      width: 100%;
      height: 580px;
      position: relative;

      .info-box {
        width: 270px;
        height: 350px;
        background: #ffffff;
        box-shadow: 0px 30px 30px 0px rgba(170, 170, 170, 0.75);
        border-radius: 5px 5px 5px 5px;
        position: absolute;
        top: 115px;
        right: 300px;
        z-index: 1;

        ul {
          padding: 33px 25px 63px 25px;
          li {
            color: #666666;
            font-weight: 400;
            &:first-child {
              width: 27px;
              height: 37px;
              background-image: url(~@/assets/image/xi-maker-drop.png);
            }

            &:nth-child(2) {
              font-size: 16px;
              color: #333333;
              margin: 17px 0 19px 0;
            }

            &:nth-child(4) {
              height: 24px;
              line-height: 27px;
              font-size: 13px;
              padding-left: 34px;
              background-image: url(~@/assets/image/tel_icon.png);
              background-repeat: no-repeat;
              margin-top: 46px;
            }

            &:nth-child(5) {
              height: 24px;
              line-height: 24px;
              font-size: 13px;
              padding-left: 34px;
              background-image: url(~@/assets/image/xi-call.png);
              background-repeat: no-repeat;
              margin-top: 22px;
            }
          }
        }
      }
    }
  }

  .message {
    width: 100%;
    padding: 100px 0 142px;
    background: #fff;
    > div:nth-child(1) {
      width: 1200px;
      margin: 0 auto;

      .content {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 31px;
        padding: 53px 0 41px;
      }
    }

    .message-box {
      width: 1282px;
      height: 721px;
      background-image: url(~@/assets/yn.png);
      background-repeat: no-repeat;
      margin: 0 auto;

      .title {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        text-align: center;
        padding-top: 50px;
      }

      .form-box {
        padding-top: 80px;
      }
    }
  }
}

/deep/ .ant-form {
  .ant-form-item-label {
    > label {
      font-size: 16px;
      color: #666666;

      &::after {
        content: '';
      }
    }
  }

  .ant-input {
    height: 45px;
    background: #f6f6f6;
    border: 1px solid #b3b3b3;
    border-radius: 6px;

    &:focus {
      border-color: none;
      box-shadow: none;
    }
  }

  textarea {
    resize: none;
  }

  .btn-up-style {
    width: 275px;
    height: 45px;
    text-align: left;
    border-color: #b3b3b3;
    background: #f6f6f6;
    font-size: 16px;

    &:hover {
      color: #999999;
    }

    &:focus {
      color: #999999;
    }

    img {
      margin-right: 14px;
    }
  }

  .file-type {
    color: #999999;
  }

  .s-btn-style {
    width: 100%;
    height: 45px;
    font-size: 18px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    background: #1285d6;
    border-radius: 6px;
  }
}
</style>
